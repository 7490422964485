import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100 mx-15 mb-10" }
const _hoisted_2 = { class: "pb-10 pb-lg-15" }
const _hoisted_3 = { class: "fw-bold d-flex align-items-center text-gray-900" }
const _hoisted_4 = { class: "form-floating mb-5" }
const _hoisted_5 = { class: "form-label mb-3" }
const _hoisted_6 = { class: "form-floating mb-5" }
const _hoisted_7 = { class: "form-label mb-3" }
const _hoisted_8 = { class: "pb-10 pb-lg-10 mt-10" }
const _hoisted_9 = { class: "fw-bold d-flex align-items-center text-gray-900" }
const _hoisted_10 = { class: "form-floating mb-5" }
const _hoisted_11 = { class: "form-label mb-3" }
const _hoisted_12 = { class: "form-floating mt-10 mb-5" }
const _hoisted_13 = { class: "form-label mb-3" }
const _hoisted_14 = { class: "form-floating mt-10 mb-5" }
const _hoisted_15 = { class: "form-label mb-3" }
const _hoisted_16 = { class: "form-floating mt-10 mb-5" }
const _hoisted_17 = { class: "form-label mb-3" }
const _hoisted_18 = { class: "pb-10 pb-lg-10 mt-10" }
const _hoisted_19 = { class: "fw-bold d-flex align-items-center text-gray-900" }
const _hoisted_20 = { class: "form-floating mb-5" }
const _hoisted_21 = { class: "form-label mb-3" }
const _hoisted_22 = { class: "form-floating mt-10 mb-5" }
const _hoisted_23 = { class: "form-label mb-3" }
const _hoisted_24 = { class: "form-floating mt-10 mb-5" }
const _hoisted_25 = { class: "form-label mb-3" }
const _hoisted_26 = { class: "form-floating mt-10 mb-5" }
const _hoisted_27 = { class: "form-label mb-3" }
const _hoisted_28 = { class: "pb-10 pb-lg-10 mt-10" }
const _hoisted_29 = { class: "fw-bold d-flex align-items-center text-gray-900" }
const _hoisted_30 = { class: "form-floating mb-5" }
const _hoisted_31 = { class: "form-label mb-3" }
const _hoisted_32 = { class: "form-floating mt-10 mb-5" }
const _hoisted_33 = { class: "form-label mb-3" }
const _hoisted_34 = { class: "form-floating mt-10 mb-5" }
const _hoisted_35 = { class: "form-label mb-3" }
const _hoisted_36 = { class: "form-floating mt-10 mb-5" }
const _hoisted_37 = { class: "form-label mb-3" }
const _hoisted_38 = { class: "form-floating mt-10 mb-5" }
const _hoisted_39 = { class: "form-label mb-3" }
const _hoisted_40 = { class: "pb-10 pb-lg-10 mt-10" }
const _hoisted_41 = { class: "fw-bold d-flex align-items-center text-gray-900" }
const _hoisted_42 = { class: "form-floating mb-5" }
const _hoisted_43 = { class: "form-label mb-3" }
const _hoisted_44 = { class: "form-floating mt-10 mb-5" }
const _hoisted_45 = { class: "form-label mb-3" }
const _hoisted_46 = { class: "form-floating mt-10 mb-5" }
const _hoisted_47 = { class: "form-label mb-3" }
const _hoisted_48 = { class: "form-floating mt-10 mb-5" }
const _hoisted_49 = { class: "form-label mb-3" }
const _hoisted_50 = { class: "form-floating mt-10 mb-5" }
const _hoisted_51 = { class: "form-label mb-3" }
const _hoisted_52 = { class: "pb-10 pb-lg-10 mt-10" }
const _hoisted_53 = { class: "fw-bold d-flex align-items-center text-gray-900" }
const _hoisted_54 = { class: "form-floating mb-5" }
const _hoisted_55 = { class: "form-label mb-3" }
const _hoisted_56 = { class: "form-floating mt-10 mb-5" }
const _hoisted_57 = { class: "form-label mb-3" }
const _hoisted_58 = { class: "form-floating mt-10 mb-5" }
const _hoisted_59 = { class: "form-label mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("solicitud.step3.titulo")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("input", {
        name: "nPlazasE",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nPlazasE) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPlazasE]
      ]),
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("solicitud.step3.nPlazas")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPlazasE",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _withDirectives(_createElementVNode("input", {
        name: "nSolicitudesE",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.nSolicitudesE) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nSolicitudesE]
      ]),
      _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("solicitud.step3.nSolicitudes")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nSolicitudesE",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t("solicitud.step3.empleoEdad")), 1)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasMenores30",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.nPersonasMenores30) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasMenores30]
      ]),
      _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("solicitud.step3.nPersonasMenores30")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasMenores30",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_12, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonas30a45",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.nPersonas30a45) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonas30a45]
      ]),
      _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("solicitud.step3.nPersonas30a45")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonas30a45",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_14, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasMas45",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.nPersonasMas45) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasMas45]
      ]),
      _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t("solicitud.step3.nPersonasMas45")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasMas45",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_16, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasEdadNC",
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.nPersonasEdadNCE) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasEdadNCE]
      ]),
      _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("solicitud.step3.nPersonasEdadNC")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasEdadNCE",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("h3", _hoisted_19, _toDisplayString(_ctx.$t("solicitud.step3.sexo")), 1)
    ]),
    _createElementVNode("div", _hoisted_20, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasMujerE",
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.nPersonasMujerE) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasMujerE]
      ]),
      _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t("solicitud.step3.nPersonasMujer")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasMujerE",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_22, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasVaronE",
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.nPersonasVaronE) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasVaronE]
      ]),
      _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t("solicitud.step3.nPersonasVaron")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasVaronE",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_24, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasOtro",
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.nPersonasOtroE) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasOtroE]
      ]),
      _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("solicitud.step3.nPersonasOtro")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasOtro",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_26, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasNCE",
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.nPersonasNCE) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasNCE]
      ]),
      _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t("solicitud.step3.nPersonasNC")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasNCE",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_28, [
      _createElementVNode("h3", _hoisted_29, _toDisplayString(_ctx.$t("solicitud.step3.caracteristicas")), 1)
    ]),
    _createElementVNode("div", _hoisted_30, [
      _withDirectives(_createElementVNode("input", {
        name: "nMujeresLargaDuracion",
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.nMujeresLargaDuracion) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nMujeresLargaDuracion]
      ]),
      _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.$t("solicitud.step3.nMujeresLargaDuracion")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nMujeresLargaDuracion",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_32, [
      _withDirectives(_createElementVNode("input", {
        name: "nVaronesLargaDuracion",
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.nVaronesLargaDuracion) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nVaronesLargaDuracion]
      ]),
      _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t("solicitud.step3.nVaronesLargaDuracion")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nVaronesLargaDuracion",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_34, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasVictima",
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.nPersonasVictima) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasVictima]
      ]),
      _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t("solicitud.step3.nPersonasVictima")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasVictima",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_36, [
      _withDirectives(_createElementVNode("input", {
        name: "nMujeresRefugiadas",
        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.nMujeresRefugiadas) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nMujeresRefugiadas]
      ]),
      _createElementVNode("label", _hoisted_37, _toDisplayString(_ctx.$t("solicitud.step3.nMujeresRefugiadas")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nMujeresRefugiadas",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_38, [
      _withDirectives(_createElementVNode("input", {
        name: "nVaronesRefugiados",
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.nVaronesRefugiados) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nVaronesRefugiados]
      ]),
      _createElementVNode("label", _hoisted_39, _toDisplayString(_ctx.$t("solicitud.step3.nVaronesRefugiados")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nVaronesRefugiados",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_40, [
      _createElementVNode("h3", _hoisted_41, _toDisplayString(_ctx.$t("solicitud.step3.contrato")), 1)
    ]),
    _createElementVNode("div", _hoisted_42, [
      _withDirectives(_createElementVNode("input", {
        name: "Indefinido",
        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.nPersonasIndefinido) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasIndefinido]
      ]),
      _createElementVNode("label", _hoisted_43, _toDisplayString(_ctx.$t("solicitud.step3.nPersonasIndefinido")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasIndefinido",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_44, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasTemporal",
        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.nPersonasTemporal) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasTemporal]
      ]),
      _createElementVNode("label", _hoisted_45, _toDisplayString(_ctx.$t("solicitud.step3.nPersonasTemporal")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasTemporal",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_46, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasFijoDiscontinuo",
        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.nPersonasFijoDiscontinuo) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasFijoDiscontinuo]
      ]),
      _createElementVNode("label", _hoisted_47, _toDisplayString(_ctx.$t("solicitud.step3.nPersonasFijoDiscontinuo")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasFijoDiscontinuo",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_48, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasFormativo",
        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.nPersonasFormativo) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasFormativo]
      ]),
      _createElementVNode("label", _hoisted_49, _toDisplayString(_ctx.$t("solicitud.step3.nPersonasFormativo")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasFormativo",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_50, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasTipoContratoNC",
        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.nPersonasTipoContratoNC) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasTipoContratoNC]
      ]),
      _createElementVNode("label", _hoisted_51, _toDisplayString(_ctx.$t("solicitud.step3.nPersonasTipoContratoNC")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasTipoContratoNC",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_52, [
      _createElementVNode("h3", _hoisted_53, _toDisplayString(_ctx.$t("solicitud.step3.jornada")), 1)
    ]),
    _createElementVNode("div", _hoisted_54, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasJornadaCompleta",
        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.nPersonasJornadaCompleta) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasJornadaCompleta]
      ]),
      _createElementVNode("label", _hoisted_55, _toDisplayString(_ctx.$t("solicitud.step3.nPersonasJornadaCompleta")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasJornadaCompleta",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_56, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasJornadaParcial",
        "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.nPersonasJornadaParcial) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasJornadaParcial]
      ]),
      _createElementVNode("label", _hoisted_57, _toDisplayString(_ctx.$t("solicitud.step3.nPersonasJornadaParcial")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasJornadaParcial",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_58, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasJornadaNC",
        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.nPersonasJornadaNC) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasJornadaNC]
      ]),
      _createElementVNode("label", _hoisted_59, _toDisplayString(_ctx.$t("solicitud.step3.nPersonasJornadaNC")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasJornadaNC",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ])
  ]))
}