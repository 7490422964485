import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100 mx-15 mb-10" }
const _hoisted_2 = { class: "pb-10 pb-lg-15" }
const _hoisted_3 = { class: "fw-bold d-flex align-items-center text-gray-900" }
const _hoisted_4 = { class: "form-floating mb-7" }
const _hoisted_5 = { class: "form-label mb-3" }
const _hoisted_6 = { class: "form-floating mb-7" }
const _hoisted_7 = { class: "form-label mb-7" }
const _hoisted_8 = { class: "form-floating mb-5" }
const _hoisted_9 = { class: "form-label mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("solicitud.step4.titulo")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("textarea", {
        name: "gradoAlcanceObjetivos",
        class: "form-control form-control-lg form-control-solid border-0 pe-10 resize-none min-h-100px pt-12",
        "data-kt-autosize": "true",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.gradoAlcanceObjetivos) = $event))
      }, null, 512), [
        [_vModelText, _ctx.gradoAlcanceObjetivos]
      ]),
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("solicitud.step4.gradoAlcanceObjetivos")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "gradoAlcanceObjetivos",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _withDirectives(_createElementVNode("textarea", {
        name: "dificultadesEncontradas",
        class: "form-control form-control-lg form-control-solid border-0 pe-10 resize-none min-h-100px pt-12",
        "data-kt-autosize": "true",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dificultadesEncontradas) = $event))
      }, null, 512), [
        [_vModelText, _ctx.dificultadesEncontradas]
      ]),
      _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("solicitud.step4.dificultadesEncontradas")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "dificultadesEncontradas",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_8, [
      _withDirectives(_createElementVNode("textarea", {
        name: "propuestasFuturas",
        class: "form-control form-control-lg form-control-solid border-0 pe-10 resize-none min-h-100px pt-12",
        "data-kt-autosize": "true",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.propuestasFuturas) = $event))
      }, null, 512), [
        [_vModelText, _ctx.propuestasFuturas]
      ]),
      _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("solicitud.step4.propuestasFuturas")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "propuestasFuturas",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ])
  ]))
}