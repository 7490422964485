import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100 mx-15 mb-10" }
const _hoisted_2 = { class: "pb-10 pb-lg-15" }
const _hoisted_3 = { class: "fw-bold d-flex align-items-center text-gray-900" }
const _hoisted_4 = { class: "pb-5" }
const _hoisted_5 = { class: "fw-bold d-flex align-items-center text-gray-900 required" }
const _hoisted_6 = { class: "form-check form-check-custom form-check-solid mb-5" }
const _hoisted_7 = {
  class: "form-check-label",
  for: "AMPLIACION_HORARIOS-check"
}
const _hoisted_8 = { class: "form-check form-check-custom form-check-solid mb-5" }
const _hoisted_9 = {
  class: "form-check-label",
  for: "VACACIONES_ESCOLARES-check"
}
const _hoisted_10 = { class: "form-floating mt-10 mb-5" }
const _hoisted_11 = { class: "form-label mb-3" }
const _hoisted_12 = { class: "form-floating mb-5" }
const _hoisted_13 = { class: "form-label mb-3" }
const _hoisted_14 = { class: "pb-10 pb-lg-10 mt-10" }
const _hoisted_15 = { class: "fw-bold d-flex align-items-center text-gray-900" }
const _hoisted_16 = { class: "form-floating mb-5" }
const _hoisted_17 = { class: "form-label mb-3" }
const _hoisted_18 = { class: "form-floating mb-5" }
const _hoisted_19 = { class: "form-label mb-3" }
const _hoisted_20 = { class: "form-floating mb-5" }
const _hoisted_21 = { class: "form-label mb-3" }
const _hoisted_22 = { class: "form-floating mb-5" }
const _hoisted_23 = { class: "form-label mb-3" }
const _hoisted_24 = { class: "form-floating mb-5" }
const _hoisted_25 = { class: "form-label mb-3" }
const _hoisted_26 = { class: "form-floating mb-5" }
const _hoisted_27 = { class: "form-label mb-3" }
const _hoisted_28 = { class: "pb-10 pb-lg-10 mt-10" }
const _hoisted_29 = { class: "fw-bold d-flex align-items-center text-gray-900" }
const _hoisted_30 = { class: "form-floating mb-5" }
const _hoisted_31 = { class: "form-label mb-3" }
const _hoisted_32 = { class: "form-floating mb-5" }
const _hoisted_33 = { class: "form-label mb-3" }
const _hoisted_34 = { class: "form-floating mb-5" }
const _hoisted_35 = { class: "form-label mb-3" }
const _hoisted_36 = { class: "form-floating mb-5" }
const _hoisted_37 = { class: "form-label mb-3" }
const _hoisted_38 = { class: "pb-10 pb-lg-10 mt-10" }
const _hoisted_39 = { class: "fw-bold d-flex align-items-center text-gray-900" }
const _hoisted_40 = { class: "form-floating mb-5" }
const _hoisted_41 = { class: "form-label mb-3" }
const _hoisted_42 = { class: "form-floating mb-5" }
const _hoisted_43 = { class: "form-label mb-3" }
const _hoisted_44 = { class: "form-floating mb-5" }
const _hoisted_45 = { class: "form-label mb-3" }
const _hoisted_46 = { class: "form-floating mb-5" }
const _hoisted_47 = { class: "form-label mb-3" }
const _hoisted_48 = { class: "form-floating mb-5" }
const _hoisted_49 = { class: "form-label mb-3" }
const _hoisted_50 = { class: "form-floating mb-5" }
const _hoisted_51 = { class: "form-label mb-3" }
const _hoisted_52 = { class: "form-floating mb-5" }
const _hoisted_53 = { class: "form-label mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("solicitud.step2.titulo")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("solicitud.step2.tipo")), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _withDirectives(_createElementVNode("input", {
        name: "AMPLIACION_HORARIOS",
        class: "form-check-input form-control form-control-solid",
        type: "checkbox",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tipoServicios) = $event)),
        value: "AMPLIACION_HORARIOS",
        id: "AMPLIACION_HORARIOS-check"
      }, null, 512), [
        [_vModelCheckbox, _ctx.tipoServicios]
      ]),
      _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("solicitud.step2.ampliacion")), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _withDirectives(_createElementVNode("input", {
        name: "VACACIONES_ESCOLARES",
        class: "form-check-input form-control form-control-solid",
        type: "checkbox",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tipoServicios) = $event)),
        value: "VACACIONES_ESCOLARES",
        id: "VACACIONES_ESCOLARES-check"
      }, null, 512), [
        [_vModelCheckbox, _ctx.tipoServicios]
      ]),
      _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("solicitud.step2.vacaciones")), 1)
    ]),
    _createVNode(_component_ErrorMessage, {
      name: "tipoServicios",
      class: "fv-plugins-message-container invalid-feedback"
    }),
    _createElementVNode("div", _hoisted_10, [
      _withDirectives(_createElementVNode("input", {
        name: "nPlazas",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.nPlazas) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPlazas]
      ]),
      _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("solicitud.step2.plazas")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPlazas",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_12, [
      _withDirectives(_createElementVNode("input", {
        name: "nSolicitudes",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.nSolicitudes) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nSolicitudes]
      ]),
      _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("solicitud.step2.solicitudes")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nSolicitudes",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("h3", _hoisted_15, _toDisplayString(_ctx.$t("solicitud.step2.edad")), 1)
    ]),
    _createElementVNode("div", _hoisted_16, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasMenores3",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.nPersonasMenores3) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasMenores3]
      ]),
      _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("solicitud.step2.nPersonasMenores3")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasMenores3",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_18, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonas3a6",
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.nPersonas3a6) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonas3a6]
      ]),
      _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("solicitud.step2.nPersonas3a6")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonas3a6",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_20, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonas7a9",
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.nPersonas7a9) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonas7a9]
      ]),
      _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t("solicitud.step2.nPersonas7a9")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonas7a9",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_22, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonas10a12",
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.nPersonas10a12) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonas10a12]
      ]),
      _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t("solicitud.step2.nPersonas10a12")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonas10a12",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_24, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonas13a16",
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.nPersonas13a16) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonas13a16]
      ]),
      _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("solicitud.step2.nPersonas13a16")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonas13a16",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_26, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasEdadNC",
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.nPersonasEdadNC) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasEdadNC]
      ]),
      _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t("solicitud.step2.nPersonasEdadNC")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasEdadNC",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_28, [
      _createElementVNode("h3", _hoisted_29, _toDisplayString(_ctx.$t("solicitud.step2.sexo")), 1)
    ]),
    _createElementVNode("div", _hoisted_30, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasMujer",
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.nPersonasMujer) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasMujer]
      ]),
      _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.$t("solicitud.step2.nPersonasMujer")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasMujer",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_32, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasVaron",
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.nPersonasVaron) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasVaron]
      ]),
      _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t("solicitud.step2.nPersonasVaron")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasVaron",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_34, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasOtro",
        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.nPersonasOtro) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasOtro]
      ]),
      _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t("solicitud.step2.nPersonasOtro")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasOtro",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_36, [
      _withDirectives(_createElementVNode("input", {
        name: "nPersonasNC",
        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.nPersonasNC) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nPersonasNC]
      ]),
      _createElementVNode("label", _hoisted_37, _toDisplayString(_ctx.$t("solicitud.step2.nPersonasNC")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nPersonasNC",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_38, [
      _createElementVNode("h3", _hoisted_39, _toDisplayString(_ctx.$t("solicitud.step2.tipologia")), 1)
    ]),
    _createElementVNode("div", _hoisted_40, [
      _withDirectives(_createElementVNode("input", {
        name: "nFamiliasNumerosa",
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.nFamiliasNumerosa) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nFamiliasNumerosa]
      ]),
      _createElementVNode("label", _hoisted_41, _toDisplayString(_ctx.$t("solicitud.step2.nFamiliasNumerosa")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nFamiliasNumerosa",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_42, [
      _withDirectives(_createElementVNode("input", {
        name: "nFamiliasMonoparental",
        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.nFamiliasMonoparental) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nFamiliasMonoparental]
      ]),
      _createElementVNode("label", _hoisted_43, _toDisplayString(_ctx.$t("solicitud.step2.nFamiliasMonoparental")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nFamiliasMonoparental",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_44, [
      _withDirectives(_createElementVNode("input", {
        name: "nFamiliasRefugiada",
        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.nFamiliasRefugiada) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nFamiliasRefugiada]
      ]),
      _createElementVNode("label", _hoisted_45, _toDisplayString(_ctx.$t("solicitud.step2.nFamiliasRefugiada")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nFamiliasRefugiada",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_46, [
      _withDirectives(_createElementVNode("input", {
        name: "nFamiliasVictima",
        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.nFamiliasVictima) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nFamiliasVictima]
      ]),
      _createElementVNode("label", _hoisted_47, _toDisplayString(_ctx.$t("solicitud.step2.nFamiliasVictima")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nFamiliasVictima",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_48, [
      _withDirectives(_createElementVNode("input", {
        name: "nFamiliasOtrasCargas",
        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.nFamiliasOtrasCargas) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nFamiliasOtrasCargas]
      ]),
      _createElementVNode("label", _hoisted_49, _toDisplayString(_ctx.$t("solicitud.step2.nFamiliasOtrasCargas")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nFamiliasOtrasCargas",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_50, [
      _withDirectives(_createElementVNode("input", {
        name: "nFamiliasOtra",
        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.nFamiliasOtra) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nFamiliasOtra]
      ]),
      _createElementVNode("label", _hoisted_51, _toDisplayString(_ctx.$t("solicitud.step2.nFamiliasOtra")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nFamiliasOtra",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_52, [
      _withDirectives(_createElementVNode("input", {
        name: "nFamiliasNC",
        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.nFamiliasNC) = $event)),
        type: "number",
        class: "form-control form-control-lg form-control-solid",
        placeholder: " "
      }, null, 512), [
        [_vModelText, _ctx.nFamiliasNC]
      ]),
      _createElementVNode("label", _hoisted_53, _toDisplayString(_ctx.$t("solicitud.step2.nFamiliasNC")), 1),
      _createVNode(_component_ErrorMessage, {
        name: "nFamiliasNC",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ])
  ]))
}